import React, { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import agent from "../../../agent";
import { useNavigate } from "react-router-dom";
import image_logo from "../../../assets/images/pic_example.png";
import { toast } from "react-toastify";
import { format } from "date-fns";

const LegacyBook = (props) => {
  const navigate = useNavigate();
  const { showLegacyPass } = props;
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.common.currentUser);
  const [formData, setFormData] = useState({
    birthDate: "",
    deathDate: "",
    memoryLane: "",
    gallery: [],
  });
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);

  const formatDate = (date) => format(new Date(date), "yyyy-MM-dd HH:mm:ss");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    const newPictures = files.map((file) => ({
      url: URL.createObjectURL(file),
      file: file,
    }));
    setImages((prevPictures) => [...prevPictures, ...newPictures]);
  };

  const handleDelete = (index) => {
    setImages((prevPictures) => prevPictures.filter((_, i) => i !== index));
  };

  const addLegacyBook = async () => {
    setLoading(true);
    try {
      const formDataObj = new FormData();

      if (formData.birthDate) {
        formDataObj.append("birthDate", formatDate(formData.birthDate));
      }
      if (formData.deathDate) {
        formDataObj.append("deathDate", formatDate(formData.deathDate));
      }

      images?.forEach((img) => {
        if (img.file != null) {
          formDataObj.append("gallery", img.file);
        } else {
          formDataObj.append("gallery", img.url);
        }
      });

      formDataObj.append("userId", currentUser?.id);

      Object.entries(formData).forEach(([key, value]) => {
        if (key !== "gallery" && key !== "birthDate" && key !== "deathDate") {
          formDataObj.append(key, value);
        }
      });

      const response = await agent.legacyBookRequest.addLegacyBook(
        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormData({
        deathDate: "",
        gallery: [],
        birthDate: "",
        memoryLane: "",
      });
    } catch (error) {
      console.error("Error adding legacy book data:", error);

      toast.error("Failed to add legacy book. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="profile-details-main"
      style={{ display: `${showLegacyPass ? "block" : "none"}` }}
    >
      <div class="profile-details profile-password legacy-info">
        <div class="profile-info ">
          <h3>My Legacy</h3>
          <div class="gallery">
            <label>Your Gallery</label>
            <ul>
              {images?.map((image, index) => (
                <li key={index}>
                  <img src={image.url} alt={`Profile ${index + 1}`} />
                  <button
                    type="button"
                    onClick={() => handleDelete(index)}
                    className=""
                  >
                    <i
                      style={{ color: "red" }}
                      class="fa fa-trash"
                      aria-hidden="true"
                    ></i>
                  </button>
                </li>
              ))}
              {images.length < 10 && (
                <li class="upload-img">
                  <img src={image_logo} alt="" />
                  <input
                    type="file"
                    onChange={handleChange}
                    multiple
                    accept="image/*"
                    ref={fileInputRef}
                  />
                </li>
              )}
            </ul>
          </div>
          <div class="legacy-field">
            <div class="input-field">
              <label>Birth Date</label>
              <input
                type="date"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleInputChange}
              />
            </div>
            <div class="input-field">
              <label>Death Date</label>
              <input
                type="date"
                name="deathDate"
                value={formData.deathDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div class="legacy-field">
            <div class="input-field w-full">
              <label>Memory Lane</label>
              <textarea
                placeholder="Enter about yourself"
                name="memoryLane"
                value={formData.memoryLane}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          <div class="legacy-btn">
            <button
              style={{ cursor: "pointer" }}
              onClick={() =>
                setFormData({
                  deathDate: "",
                  gallery: [],
                  birthDate: "",
                  memoryLane: "",
                })
              }
            >
              Cancel
            </button>
            <button onClick={addLegacyBook} class="save-btn">
              {loading ? <div className="upload_loader-two"></div> : "Add Book"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegacyBook;
