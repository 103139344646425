import {
  APP_LOAD,
  FETCH_ALL_AGENTS,
  FETCH_ALL_BLOGS,
  FETCH_ALL_FAQS,
  FETCH_ALL_LOCATIONS,
  FETCH_BLOG,
  FETCH_LEGACY_BOOKS,
  FETCH_LOCATION,
  FETCH_MY_ORDER,
  FORGOT_PASS,
  FORGOT_RESET,
  LOGIN,
  LOGIN_RESET,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  RESET_PASSWORD_EMPTY,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_RESET,
  UPDATE_USER_PASS,
  UPDATE_USER_PASS_RESET,
  VERIFY_OTP,
  VERIFY_OTP_RESET,
  FETCH_BOOK_DETAILS,
} from "../constants/actionTypes";

const defaultState = {
  appLoaded: false,
  loginSuccess: false,
  loginError: null,
  registerSuccess: false,
  registerError: null,
  currentUser: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true,
        currentUser:
          action.payload && action.payload.data && action.payload.data
            ? action.payload.data
            : null,
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        loginSuccess:
          action.error || (action.payload && action.payload.status)
            ? true
            : false,
        loginError:
          action.payload && !action.payload.status
            ? action.payload.message
            : null,
        currentUser:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case LOGIN_RESET:
      return {
        ...state,
        loginSuccess: false,
        loginError: null,
        // currentUser: null,
      };
    case FORGOT_PASS:
      return {
        ...state,
        forgotSuccess:
          action.payload && action.payload.status
            ? action.payload.status
            : false,
        forgotMessage:
          action.payload && action.payload.message
            ? action.payload.message
            : null,
      };
    case FORGOT_RESET:
      return {
        ...state,
        forgotSuccess: false,
        forgotMessage: null,
      };
    case VERIFY_OTP:
      return {
        ...state,
        otpSuccess:
          action.payload && action.payload.status
            ? action.payload.status
            : false,
        otpMessage:
          action.payload && action.payload.message
            ? action.payload.message
            : null,
      };
    case VERIFY_OTP_RESET:
      return {
        ...state,
        otpSuccess: false,
        otpMessage: null,
      };
    case FETCH_ALL_LOCATIONS:
      return {
        ...state,
        allLocations:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case FETCH_ALL_FAQS:
      return {
        ...state,
        allFaqs:
          action.payload && Array.isArray(action.payload)
            ? action.payload
            : null,
      };
    case FETCH_LOCATION:
      return {
        ...state,
        LocationData:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data[0]
            : null,
      };
    case FETCH_BOOK_DETAILS:
      return {
        ...state,
        BookData:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case FETCH_ALL_BLOGS:
      return {
        ...state,
        allBlogs:
          action.payload && action.payload.status && action.payload.blogs
            ? action.payload.blogs
            : null,
      };
    case FETCH_BLOG:
      return {
        ...state,
        BlogData:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case FETCH_MY_ORDER:
      return {
        ...state,
        OrderData:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case FETCH_LEGACY_BOOKS:
      return {
        ...state,
        legacyData:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        rPassSuccess:
          action.payload && action.payload.status
            ? action.payload.status
            : false,
        rPassMessage:
          action.payload && action.payload.message
            ? action.payload.message
            : null,
      };
    case RESET_PASSWORD_EMPTY:
      return {
        ...state,
        rPassSuccess: false,
        rPassMessage: null,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        updateSuccess:
          action.error || (action.payload && action.payload.status)
            ? true
            : false,
        updateError:
          action.payload && !action.payload.status
            ? action.payload.message
            : null,
        currentUser:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case UPDATE_USER_DATA_RESET:
      return {
        ...state,
        updateSuccess: false,
        updateError: null,
      };
    case UPDATE_USER_PASS:
      return {
        ...state,
        updatePassSucc:
          action.error || (action.payload && action.payload.status)
            ? true
            : false,
        updatePassError:
          action.payload && action.payload.message
            ? action.payload.message
            : null,
      };
    case UPDATE_USER_PASS_RESET:
      return {
        ...state,
        updatePassSucc: false,
        updatePassError: null,
      };
    case FETCH_ALL_AGENTS:
      return {
        ...state,
        allAgents:
          action.payload && action.payload.status && action.payload.data
            ? action.payload.data
            : null,
      };
    case LOGOUT:
      return {
        ...state,
        currentUser: null,
      };
    default:
      return state;
  }
}
