import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import bnrImage from "../../assets/images/compress/bg-img-c.png";
import cookies from "../../assets/images/cookie_img.png";
import weDoImg from "../../assets/images/home_what.png";
import weDoTwo from "../../assets/images/home_what_two.png";
import tributeImg from "../../assets/images/tribute_img.png";
import Jungle from "../../assets/images/jungle.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import familytypes from "../../assets/images/family_types_img.png";
import familyburialImg from "../../assets/images/family_burial.png";
import GreenImg from "../../assets/images/green_img.png";
import plansImg from "../../assets/images/plans_img.png";
import memorialImg from "../../assets/images/memorial_img.png";
import NatureOne from "../../assets/images/Nature_first.jpg";
import NatureTwo from "../../assets/images/Nature_second.jpg";
import NatureThree from "../../assets/images/Nature_third.jpg";
import NatureFour from "../../assets/images/Nature_four.jpg";
import NatureFive from "../../assets/images/Nature_five.jpg";
import NatureSix from "../../assets/images/Nature_six.jpg";
import futurePlan from "../../assets/images/future_planning.jpg";
import KeepSmile from "../../assets/images/Keep_smile.jpg";
import Flowers from "../../assets/images/flowers.jpg";
import NewImg from "../../assets/images/img_two.png";
import kendalTwo from "../../assets/images/kendaltwo.png";
import Forest from "../../assets/images/forest.png";
import Couples from "../../assets/images/couples.png";
import Legacy from "../../assets/images/legacy.png";
import Story from "../../assets/images/story.png";

import {
  setResetComplete,
  setResetPass,
  setShowForgot,
  setShowLogin,
  setShowRegister,
  setVerifyOtp,
} from "../../reducers/slices/headerSlice";
import Visiter from "../Common/Visiter";
import GiftPopup from "../Common/GiftPopup";
import TeamSection from "./TeamSection";
import Overview from "./Overview";
import WhyInspireMeadows from "./WhyInspireMeadows";
import Advisor from "./Advisor";
import Pets from "./Pets";
import Purchase from "./Purchase";
import BannerComponent from "../Common/Optemize/BannerComponent";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [showCookies, setShowCookies] = useState(false);
  const [visiterTab, setVisiterTab] = useState(false);
  const [giftPopupToggle, setGiftPopupToggle] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const handleShowLogin = () => {
    dispatch(setShowLogin(true));
    dispatch(setShowRegister(false));
    dispatch(setResetPass(false));
    dispatch(setShowForgot(false));
    dispatch(setVerifyOtp(false));
    dispatch(setResetPass(false));
    dispatch(setResetComplete(false));
  };

  useEffect(() => {
    const cookieValue = localStorage.getItem("cookies");
    const visiterGift = localStorage.getItem("visiterGift");
    if (!visiterGift && cookieValue) {
      setVisiterTab(true);
    }
    if (!cookieValue) {
      // handleShowLogin()
      setShowCookies(true);
      // localStorage.setItem("cookies",true);
    }
  }, []);

  const handleClose = () => {
    setShowCookies(false);
    setVisiterTab(true);
    localStorage.setItem("cookies", false);
  };
  const handleAccept = () => {
    setShowCookies(false);
    setVisiterTab(true);
    localStorage.setItem("cookies", true);
  };

  const togglePlayPause = () => {
    setShowImage(false);
    setIsPlaying(true);
    const video = videoRef.current;
    if (!video) {
      return;
    }

    if (video.paused) {
      video
        .play()
        .then(() => {
          setShowImage(false);
          setIsPlaying(true);
          video.controls = true;
        })
        .catch((error) => {
          console.error("Video playback error:", error);
          video.controls = false;
        });
    } else {
      video.pause();
      setIsPlaying(false);
      video.controls = false;
    }
  };

  // const handleVideoEnded = () => {
  //   setIsPlaying(false);
  //   setShowImage(true);
  // };

  const crossHandle = () => {
    localStorage.setItem("visiterGift", true);
    setVisiterTab(false);
    setGiftPopupToggle(false);
  };

  const showGiftPopup = () => {
    setGiftPopupToggle(true);
    setVisiterTab(false);
  };

  return (
    <Fragment>
      {visiterTab && (
        <Visiter crossHandle={crossHandle} showGiftPopup={showGiftPopup} />
      )}
      {giftPopupToggle && (
        <GiftPopup
          crossHandle={crossHandle}
          setGiftPopupToggle={setGiftPopupToggle}
        />
      )}
      <section id="banner-main" className="home-banner-main">
        <BannerComponent
          showImage={showImage}
          setIsPlaying={setIsPlaying}
          setShowImage={setShowImage}
          videoRef={videoRef}
        />

        <div className="banner-text-main ">
          <div className="container">
            <div className="banner-text home-banner">
              <h1>{isPlaying ? "" : "Natural Burials at Inspired Meadows"}</h1>
              {isPlaying ? (
                ""
              ) : (
                <p>
                  The UK is dealing with an overcrowding crisis in our
                  cemeteries. At Inspired Meadows, our natural burials, family
                  memorial gardens and green burials offer the ideal resting
                  spot for you, your loved ones, and even your pets.
                </p>
              )}
              {!isPlaying && (
                <div className="play-link" onClick={togglePlayPause}>
                  <i
                    className={`fa ${isPlaying ? "fa-pause" : "fa-play"}`}
                    aria-hidden="true"
                  ></i>{" "}
                  {isPlaying ? "Pause Video" : "Watch Video"}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="what-we-do">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={weDoTwo} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>What We Do</h2>
                <p>
                  At Inspired Meadows, we provide sustainable end-of-life
                  planning for the eco-conscious generation. Located in the
                  beautiful Southern Lake District, our meadowland offers
                  natural burial plots for an environmentally-friendly farewell
                  - and you can reserve your space at any age, alongside space
                  for your loved ones and, yes, your pets!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="memorial-tribute-service" className="home-natural-burial">
        <div className="container">
          <div className="row">
            <div className="tribute-head-col">
              <div className="tribute-head">
                <h2>Why Choose a Natural Burial?</h2>
                <img src={Jungle} />
                <p>
                  So, why opt for a natural burial? Why it’s perhaps not the
                  most interesting thing to plan out, here’s why it matters:
                </p>
                <Link to="/schedule-a-call">
                  Speak to an Advisor <img src={anglerighticon} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="types-family-main" className="home-types-main">
        <div className="container">
          <div className="row">
            <div className="family-types-col">
              <div className="family-types">
                <div className="family-types-box">
                  <div className="types-details">
                    <h3>Returning to Nature</h3>
                    <p>
                      At its core, a natural burial is about returning to
                      nature. By choosing biodegradable materials and foregoing
                      harmful chemicals, we allow our bodies to gently decompose
                      and nourish the soil, contributing to the growth and
                      vitality of the ecosystem.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureOne} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureTwo} />
                  </div>
                  <div className="types-details home-second-types">
                    <h3>A Serene Sanctuary</h3>
                    <p>
                      Beyond the environmental benefits, natural burials provide
                      a sense of peace and tranquility that is hard to find
                      elsewhere. Imagine a final resting place surrounded by
                      wildflowers, trees, and the soft sounds of nature – a
                      serene sanctuary where literally your
                      great-great-great-grandchildren can visit! Plus, at
                      Inspired Meadows we offer beautiful, expansive burial
                      plots that are 10 times larger than the industry standard.
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details home-third-types">
                    <h3>Personalised Memorials</h3>
                    <p>
                      One of the most compelling aspects of natural burial is
                      the opportunity for personalisation - no more dreary grey
                      headstones! Rather than your final resting place being in
                      a crowded cemetery, we give you the option to customise
                      your burial plot with hand-selected trees and flowers,
                      creating a living memorial that evolves and grows over
                      time. It's a beautiful way to honour the essence of your
                      loved one (or yourself!) and create a lasting tribute that
                      future generations (don’t forget we offer a 999-year
                      lease) can visit and cherish.{" "}
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureThree} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureFour} />
                  </div>
                  <div className="types-details home-third-types">
                    <h3>Preserving Natural Landscapes</h3>
                    <p>
                      Think you’re already eco-friendly in your day-to-day
                      choices? How about positively contributing to the natural
                      environment well after you’ve gone? When you choose a
                      natural burial at Inspired Meadows, you're not just
                      creating a meaningful resting place for your loved one or
                      yourself, you're actually contributing to the preservation
                      of the UK’s natural landscapes. Our burial grounds are
                      carefully designed to promote biodiversity, protect
                      wildlife habitats, and maintain the delicate balance of
                      local ecosystems - how’s that for a personal legacy?
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details home-four-types">
                    <h3>Enduring Care and Commitment</h3>
                    <p>
                      The UK is dealing with an overcrowding crisis in our
                      cemeteries, with some proposals to “re-use” graves. At
                      Inspired Meadows, we don’t believe that the right approach
                      is in digging up old graves - we - like you- believe that
                      your resting place should be uninterrupted. That's why we
                      offer a unique 999-year "forever plot" promise, ensuring
                      that you or your loved one's resting place will remain
                      protected and cared for over the centuries. Plus, our
                      dedicated team provides ongoing maintenance for a one-time
                      fee, so you can have peace of mind knowing that the beauty
                      and serenity of the burial ground will endure.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureFive} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureSix} />
                  </div>
                  <div className="types-details home-five-types">
                    <h3>A Meaningful Choice</h3>
                    <p>
                      Ultimately, choosing a natural burial is about aligning
                      your values with your final farewell, while also giving
                      future generations a place to remember you. So whether
                      you're preplanning for yourself or honouring a loved one,
                      a natural burial at Inspired Meadows offers a unique and
                      meaningful way to create a lasting legacy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="about-family-main"
        className="home-about-naturals kendal-perfect-main"
      >
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Kendal: The Perfect Spot for Your Family’s Forever Plot</h2>
                <p>
                  At Inspired Meadows Kendal, our natural meadowland is the
                  ideal resting spot for both natural burials and memorial
                  gardens, right in the centre of the majestic Lake District.
                </p>
                <p>
                  Whether you opt for something simple like a natural burial, or
                  choose a sprawling plot for your entire family (pets
                  included!) our Kendal location offers beauty, peace, and a
                  connection to the natural outdoors. Instead of risking the
                  possibility that the government decides to re-use your grave
                  long after you’re gone, you’ll instead become a part of
                  Kendal's rich, natural landscape. With a beautiful natural
                  setting – and constant upkeep by our staff – your resting
                  place will remain a comforting space for your family to return
                  to over and over.
                </p>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={kendalTwo} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="home-about-naturals">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Forest} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Why Choose Inspired Meadows Natural Burials</h2>
                <p>
                  In the UK, traditional cemeteries are running out of space,
                  with the government even proposing a policy of “grave
                  recycling” after 75 years. As we mentioned above, we believe
                  your final resting place should be truly permanent, which is
                  why each of our plots is secured for 999 years. The bottom
                  line is simple: when you choose Inspired Meadows, your burial
                  plot becomes a protected natural space, rather than a
                  government commodity.
                </p>
                <Link to="/schedule-a-call">
                  Schedule a Call <img src={anglerighticon} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="burials-process-main" className="home-plans-main">
        <div className="container">
          <div className="row">
            <div className="burials-process-col">
              <div className="burials-process">
                <img src={futurePlan} />
                <div className="burials-details">
                  <h3>Future Planning</h3>
                  <p>
                    We understand that death isn’t something you necessarily
                    want to discuss over cocktails with friends; it’s more one
                    of those dreaded, quickly-buried thoughts that occurs at 3
                    AM. But we reject this taboo; if you’re already planning for
                    retirement, there’s no reason you can’t plan for your end of
                    life. Not only does this take the emotional and financial
                    burden off your loved ones, but it also ensures that your
                    burial and memorial goes exactly as you’d wish.
                  </p>
                </div>
              </div>
            </div>
            <div className="burials-process-col">
              <div className="burials-process bg-light">
                <img src={KeepSmile} />
                <div className="burials-details">
                  <h3>Keeping Families Together - Whatever They Look Like</h3>
                  <p>
                    Pets are family, and if you’re like us, you’ll believe that
                    our furry friends deserve as much dignity in death as the
                    rest of us. This is why unlike traditional cemeteries, we
                    welcome your beloved pets to rest alongside you after death.
                    We also offer large group plots, so you and your loved ones
                    stay together even after death.
                  </p>
                </div>
              </div>
            </div>
            <div className="burials-process-col">
              <div className="burials-process third">
                <img src={Flowers} />
                <div className="burials-details">
                  <h3>Nature Does the Work</h3>
                  <p>
                    Instead of manicured lawns and stone monuments, we let
                    nature create a living memorial. Native wildflowers, trees,
                    and wildlife transform our meadowland into a thriving
                    ecosystem that isn’t just beautiful to visit, but promotes
                    the local natural landscape. And for a simple, one-time
                    maintenance fee of £175, we’ll take care of your space -
                    forever.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="what-we-do phome-payment-plan">
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Payment Plans</h2>
                <p>
                  We believe everyone should have access to a meaningful,
                  environmental farewell, which is why we offer flexible payment
                  plans with a 25% down payment. The ecological benefits of
                  natural burial are well documented, and we don’t think that
                  doing the right thing for the planet should be a luxury.
                </p>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Couples} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="what-we-do">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Legacy} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>A Living Legacy</h2>
                <p>
                  Every natural burial at Inspired Meadows helps create
                  something bigger - protected wildlife habitats, restored
                  ecosystems, and green spaces that future generations can
                  enjoy. Your final choice becomes part of the solution, not
                  part of the problem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="home-place-main">
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Your Place in Nature's Story</h2>
                <p>
                  Whether you're planning ahead or making arrangements for a
                  loved one, we're here to help you create a farewell that
                  matters. Contact us today at Inspired Meadows to see how
                  natural burials can transform your end-of-life planning from a
                  somber necessity into something that brings you that
                  all-important peace of mind.
                </p>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={Story} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <Overview />
      <WhyInspireMeadows />

      <Purchase />

      <Advisor />

      <Pets />
      <TeamSection /> */}
      {showCookies && (
        <div>
          <div
            style={{ position: "fixed" }}
            className={`overlay-cookie ${showCookies ? "logout-active" : ""}`}
            // className={`${showCookies ? 'logout-active' : ''}`}
          />
          <div class="cookie-main">
            <img src={cookies} alt="cookies" />
            <span>Cookies Consent</span>
            <p>
              We use cookies to enhance your browsing experience and provide
              personalized content. By clicking "Accept" you agree to our use of
              cookies.
            </p>
            <ul>
              <li>
                <span class="no-btn" onClick={handleClose}>
                  Reject
                </span>
              </li>
              <li>
                <span class="active yes-btn" onClick={handleAccept}>
                  Accept
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
