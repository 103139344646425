import React from 'react'
import bannerimg from "../../assets/images/Natural_bg_img.png";
import familyburialImg from "../../assets/images/family_burial.png";
import Natural_first from "../../assets/images/Natural_first.png";
import Natural_second from "../../assets/images/Natural_second.png";
import natural_three from "../../assets/images/natural_three.png";
import familytypes from "../../assets/images/family_types_img.png";
import bgimg from "../../assets/images/bg_img.png";
import FamilyBurailsPlots from "../../assets/images/family_burials_plots.jpg";
import NatureTwo from "../../assets/images/Nature_second.jpg";
import NatureSix from "../../assets/images/Nature_six.jpg";
import NatureFour from "../../assets/images/Nature_four.jpg";




const Familyburials = () => {
    return (
        <>
            <section id="banner-img-main">
            <div className="banner-overlay">
</div>
                <div className="custom-banner-img">
                    <div>
                        <img src={bannerimg} alt="overview image" />
                    </div>
                    {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
                </div>
                <div className="custom-banner-text">
                    <div className="container">
                        <h1>Family Burial Plots </h1>
                        <p>Create a lasting legacy with our thoughtfully designed family burial plots, where you and your loved ones can rest together, forever.</p>
                    </div>
                </div>
            </section>

            <section id='about-family-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='family-burial-img-col'>
                            <div className='family-burial-img'>
                                <img src={FamilyBurailsPlots} />
                            </div>
                        </div>
                        <div className='family-detail-col'>
                            <div className='family-detail'>
                                <h2>What Are Family Burial Plots?</h2>
                                <p>Put simply, family burial plots are designated spaces where pretty much your entire family
                                    can be laid to rest together. Unlike traditional single plots, which usually only have
                                    space for one person, family burial plots provide a shared resting place that brings
                                    together generations of loved ones. At Inspired Meadows, we’ve designed these plots
                                    with flexibility and care, offering the option to include cremated remains and -
                                    unlike traditional burial grounds - even your cherished pets. </p>
                                <p>More than just a burial space, a family plot at our Lake District meadowland becomes a thriving ecosystem of its own; with plants, trees and flowers marking your spot, as opposed to a dreary, depressing tombstone.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Burials-main" className='family-burials-main'>
                <div className="container">
                    <div className="row">
                        <div className="Burials-head-col">
                            <div className="Burials-head">
                                <h2>What are the Benefits of Choosing a Family Burial Plot?</h2>
                            </div>
                        </div>
                        <div className="burials-desk">
                            <div className="burials-col">
                                <div className="burials">
                                    <img src={Natural_first} alt="" />
                                    <h3>Rest Together, Always</h3>
                                    <p>
                                        We understand the importance of staying close to your loved ones, which is why our family plots allow you to share a final resting place, forever! With options to pre-purchase, you can plan ahead and secure spaces for family members, friends, and yourself.
                                    </p>
                                </div>
                            </div>
                            <div className="burials-col">
                                <div className="burials">
                                    <img src={Natural_second} alt="" />
                                    <h3>Accommodate Your Beloved Pets</h3>
                                    <p>
                                        We get it - your pets are part of the family too. We’re one of the only burial grounds in the UK to allow owners to be buried alongside their pets, and we’re proud of that distinction. Our pets deserve just as much dignity in death as we do, and we want you to be able to honour the deep bonds you’ve shared with them throughout their life.
                                    </p>
                                </div>
                            </div>
                            <div className="burials-col">
                                <div className="burials">
                                    <img src={natural_three} alt="" />
                                    <h3>Spacious, Personalised Designs</h3>
                                    <p>
                                        Our plots are exceptionally generous, with burial plots spanning 16m², and cremated remains plots at 8m² - ten times larger than the industry standard. And with regular upkeep by our team, your family burial plots will, over time, transform into a living, personalised memorial garden that continues to thrive long after you’re gone.
                                    </p>
                                </div>
                            </div>
                            <div className="burials-col">
                                <div className="burials">
                                    <img src={natural_three} alt="" />
                                    <h3>Eco-Friendly Burials</h3>
                                    <p>
                                        At Inspired Meadows, we’re dedicated to protecting the planet. Our natural burial options minimise the use of non-biodegradable materials and harmful chemicals, promoting sustainability. Plus, when you (or your loved ones) plant trees and flowers in your plot, you actively contribute to the local ecology and create a beautiful, living legacy well into the afterlife.                                    </p>
                                </div>
                            </div>
                            <div className="burials-col">
                                <div className="burials">
                                    <img src={natural_three} alt="" />
                                    <h3>Hassle-Free Maintenance</h3>
                                    <p>
                                        We know how important it is for your family to feel at ease. That’s why we take care of all plot maintenance for a one-time fee of £175, meticulously tending to the grounds so your family can always experience the peace and beauty of the space without any extra responsibility.                                        </p>
                                </div>
                            </div>
                            <div className="burials-col">
                                <div className="burials">
                                    <img src={natural_three} alt="" />
                                    <h3>Forever Isn’t Just a Word</h3>
                                    <p>
                                        Unlike many burial plots in the UK, which are leased under Exclusive Right of Burial contracts requiring renewal after 50 or 100 years, we at Inspired Meadow offer exclusive 999-year “forever plots.” Put simply this ensures that your family’s resting place will remain undisturbed, offering lasting peace of mind.                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='types-family-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='type-family-head-col'>
                            <div className='type-family-head'>
                                <h2>Types of Family Burial Plots Available</h2>
                                <p>
                                    Finally, we understand that no two families are alike—and your burial space shouldn’t be,
                                    either. That’s why we offer bespoke options to suit your unique needs:
                                </p>
                            </div>
                        </div>
                        <div className='family-types-col'>
                            <div className='family-types'>
                                <div className='family-types-box'>
                                    <div className='types-details'>
                                        <h3>Cremation-Only Plots</h3>
                                        <p>
                                            At Inspired Meadows we specialise in cremation burials, which are a more sustainable and space-conscious option compared to full body burials. Cremation plots can also accommodate multiple urns, making them ideal for family members who wish to share a single, serene resting space.
                                        </p>
                                    </div>
                                    <div className='family-types-img'>
                                        <img src={NatureTwo} />
                                    </div>
                                </div>
                                <div className='family-types-box'>
                                    <div className='family-types-img'>
                                        <img src={NatureSix} />
                                    </div>
                                    <div className='types-details'>
                                        <h3>Companion Plots</h3>
                                        <p>
                                            For couples or close family members who want to rest together in an adjacent or <span>“layered”</span> plot, our companion plots provide a touching way to stay connected, even in eternity.
                                        </p>
                                    </div>
                                </div>
                                <div className='family-types-box third'>
                                    <div className='types-details'>
                                        <h3>Personalised Family Plots</h3>
                                        <p>
                                            Remember in <span>Gilmore Girls</span>, when Emily Gilmore has to kick out an unpleasant aunt to make room for Rory in the Gilmore burial plot? We don’t have the same space limits - larger family plots allow for the burial of multiple family members over time, surrounded by the beauty of your chosen flowers and trees. These plots can grow with your family, preserving your legacy in a natural, peaceful setting.
                                        </p>
                                    </div>
                                    <div className='family-types-img'>
                                        <img src={NatureFour} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='family-why-choose-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='family-why-head-col'>
                            <div className='family-why-head'>
                                <h2>Why Choose Us for Your Family Burial Plot?</h2>
                            </div>
                        </div>
                        <div className='family-full-col'>
                            <div className='family-full'>
                                <h3>A New Approach to Afterlife Planning</h3>
                                <p>We're here to change the way people think about funerals and afterlife planning. At Inspired Meadows, we strive to destigmatise discussions about death, and we want to encourage even young people to engage in forward-thinking, sensible planning. By breaking away from the outdated traditions of churchyard burials, we offer modern, sustainable solutions tailored to today’s families.</p>
                            </div>
                        </div>
                        <div className='family-half-col'>
                            <div className='family-half'>
                                <h3>Built on Sustainability </h3>
                                <p>
                                    Our commitment to natural and eco-friendly burials ensures that your final resting place benefits the earth. From minimising waste to cultivating local ecosystems, every element of our approach supports the planet you leave behind.
                                </p>
                            </div>
                        </div>
                        <div className='family-half-col'>
                            <div className='family-half'>
                                <h3>Transparent Payment Plans </h3>
                                <p>
                                    We understand that cost can be a concern, which is why we offer flexible, interest-free payment plans to make afterlife planning accessible to all. With a 25% downpayment, you can secure your family’s burial plot with peace of mind, knowing you can budget in a way that works for you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='new-cta-main'>
                <div className='container'>
                    <div className='new-cta'>
                        <img src={bgimg} />
                        <div className='new-cta-details'>
                            <h3>Plan Your Family Legacy Today</h3>
                            <p>Don’t wait for tomorrow to secure a meaningful resting place for your family. 
                                Whether you’re looking to accommodate future generations, honour sustainability, 
                                or simply ensure a peaceful place for you and your beloved furry friend, Inspired
                                 Meadows is here for you.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Familyburials
