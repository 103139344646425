export const APP_LOAD = "APP_LOAD";
export const LOGIN = "LOGIN";
export const LOGIN_RESET = "LOGIN_RESET";
export const REGISTER = "REGISTER";
export const REGISTER_RESET = "REGISTER_RESET";
export const LOGOUT = "LOGOUT";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const FETCH_ALL_LOCATIONS = "FETCH_ALL_LOCATIONS";
export const FETCH_LOCATION = "FETCH_LOCATION";
export const FETCH_ALL_BLOGS = "FETCH_ALL_BLOGS";
export const FETCH_BLOG = "FETCH_BLOG";
export const FORGOT_PASS = "FORGOT_PASS";
export const FORGOT_RESET = "FORGOT_RESET";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_EMPTY = "RESET_PASSWORD_EMPTY";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_RESET = "UPDATE_USER_DATA_RESET";
export const UPDATE_USER_PASS = "UPDATE_USER_PASS";
export const UPDATE_USER_PASS_RESET = "UPDATE_USER_PASS_RESET";
export const FETCH_ALL_AGENTS = "FETCH_ALL_AGENTS";
export const FETCH_ALL_FAQS = "FETCH_ALL_FAQS";
export const FETCH_MY_ORDER = "FETCH_MY_ORDER";
export const FETCH_LEGACY_BOOKS = "FETCH_LEGACY_BOOKS";
export const FETCH_BOOK_DETAILS = "FETCH_BOOK_DETAILS";
