import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
// import facebookFunction from "./script";

const SEO = ({ title, description, keywords, href }) => {
  const metaTags = [
    { charSet: "utf-8" },
    { name: "description", content: description },
    { name: "keywords", content: keywords },
  ];

  const filteredMetaTags = metaTags.filter((meta) => meta.content);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "2065617500491885");
      fbq("track", "PageView");
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      {filteredMetaTags.map((meta, index) => (
        <meta key={index} {...meta} />
      ))}
      <link rel="canonical" href={href} />
      {/* Add Facebook Open Graph tags */}
      {/* <script async>{`newFunction()`}</script> */}
      {/* <noscript>
        `
        <img
          height="1"
          width="1"
          style="display: none"
          src="https://www.facebook.com/tr?id=2065617500491885&ev=PageView&noscript=1"
          alt="none"
        />
        `
      </noscript> */}
    </Helmet>
  );
};

// const newFunction = async () => {
//   (function (f, b, e, v, n, t, s) {
//     if (f.fbq) return;
//     n = f.fbq = function () {
//       n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
//     };
//     if (!f._fbq) f._fbq = n;
//     n.push = n;
//     n.loaded = !0;
//     n.version = "2.0";
//     n.queue = [];
//     t = b.createElement(e);
//     t.async = !0;
//     t.src = v;
//     s = b.getElementsByTagName(e)[0];
//     s.parentNode.insertBefore(t, s);
//   })(
//     window,
//     document,
//     "script",
//     "https://connect.facebook.net/en_US/fbevents.js"
//   );
//   window.fbq("init", "2065617500491885");
//   window.fbq("track", "PageView");
// };

export default SEO;
