import React, { Fragment, useState, useEffect } from "react";
import videoThumb from "../../assets/images/faq-video-img.png";
import AccordionItem from "./AccordionItem";
import { connect } from "react-redux";
import { FETCH_ALL_FAQS } from "../../constants/actionTypes";
import { Link } from "react-router-dom";

import agent from "../../agent";
import bnrImage from "../../assets/images/FAQ_bg_img.png";
import Loader from "../components/Loader";
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";

const mapStateToProps = (state) => ({
  allLocations: state.common.allFaqs,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadPage: async () => {
    try {
      const response = await agent.common.getFaqDetail();
      const data = response?.data;
      dispatch({
        type: FETCH_ALL_FAQS,
        payload: data,
      });
    } catch (error) {}
  },
});

const MainView = (props) => {
  const { allLocations, onLoadPage } = props;
  const [faqs, setFaqs] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [click, setClick] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    onLoadPage();
  }, []);

  useEffect(() => {
    onLoadPage()
      .then(() => {
        setInProgress(true);
      })
      .catch((error) => {
        setInProgress(false);
        console.error("API call error:", error);
      });
  }, []);

  useEffect(() => {
    if (allLocations) {
      setFaqs(allLocations);
    }
  }, [allLocations]);

  const ViewClick = () => {
    setClick(!click);
  };

  const toggleAccordion = (index) => {
    if (expandedIndex === index) {
      // Clicked on an already open item, so close it.
      setExpandedIndex(null);
    } else {
      // Clicked on a new item, so open it.
      setExpandedIndex(index);
    }
  };
  return (
    <Fragment>
      <section id="banner-img-main">
        <div className="custom-banner-img">
          <img src={bnrImage} alt="background-Image" />
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Frequently Asked Questions</h1>
          </div>
        </div>
      </section>
      {!inProgress && <Loader />}
      <section id="Faq-main">
        <div class="container">
          <div class="row">
            <div class="faq-col">
              <div class="faq">
                <h2>Total ({faqs.length})</h2>
                <ul className="faq-desk">
                  {faqs?.map((faq, index) => (
                    <AccordionItem
                      key={index}
                      number={index}
                      title={faq?.title}
                      content={faq?.description}
                      videoUrl={faq?.videoUrl}
                      isExpanded={expandedIndex === index}
                      toggleAccordion={toggleAccordion}
                    />
                  ))}
                </ul>
                <ul className="faq-mobile">
                  {faqs?.map((faq, index) =>
                    index < 3 ? (
                      <AccordionItem
                        key={index}
                        number={index}
                        title={faq?.title}
                        content={faq?.description}
                        videoUrl={faq?.videoUrl}
                        isExpanded={expandedIndex === index}
                        toggleAccordion={toggleAccordion}
                      />
                    ) : null
                  )}
                  {click &&
                    faqs
                      .slice(3)
                      .map((faq, index) => (
                        <AccordionItem
                          key={index}
                          number={index + 3}
                          title={faq?.title}
                          content={faq?.description}
                          videoUrl={faq?.videoUrl}
                          isExpanded={expandedIndex === index}
                          toggleAccordion={toggleAccordion}
                        />
                      ))}
                </ul>
              </div>
            </div>
            <div class="view-more-agent-col">
              <div class="view-more-agent">
                <button onClick={ViewClick}>
                  {click ? "View Less" : "View more"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Adviser-main">
        <div className="future-planing overview-future-planing">
          <div className="container">
            <div className="row">
              <div className="talk-adviser-col">
                <div className="talk-adviser">
                  <div className="msg-img">
                    <img src={msgbox} alt="message box" />
                  </div>
                  <div className="adviser-head">
                    <h3>Speak to an Advisor</h3>
                    <p>
                      For more information please contact us for a no obligation
                      call. Please click on SCHEDULE A CALL button
                    </p>
                  </div>
                  <div className="adviser-contact">
                    <Link to="/schedule-a-call">
                      Schedule a Call <img src={anglerighticon} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
