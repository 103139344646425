import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import agent from "../../agent";
import arrow_left from "../../assets/images/arrow_left.png";
import SEO from "../SEO/SEO";
import { FETCH_BOOK_DETAILS } from "../../constants/actionTypes";
const mapStateToProps = (state) => ({
  ...state,
  BookData: state.common.BookData,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadLocation: async (lid) => {
    try {
      const response = await agent.legacyBookRequest.getAllLegacyBookDetails(
        lid
      );
      const data = response;
      dispatch({
        type: FETCH_BOOK_DETAILS,
        payload: data,
      });
    } catch (error) {
      // Handle error here if needed
    }
  },
});

const Detail = (props) => {
  const { BookData, onLoadLocation } = props;
  const navigate = useNavigate();
  const [showCommentBox, setShowCommentBox] = useState(false);
  let params = useParams();
  const { id } = useParams();
  const location = useLocation();
  const [mainImage, setMainImage] = useState("");
  const currentUser = useSelector((state) => state.common.currentUser);

  // const viewMode = location.pathname.includes("/map") ? "map" : "details";
  useEffect(() => {
    if (params) {
      onLoadLocation(params.id);
    }
  }, [params, onLoadLocation]);

  useEffect(() => {
    if (BookData?.gallery?.length > 0) {
      setMainImage(BookData.gallery[0]);
    }
  }, [BookData]);

  const showComment = () => {
    setShowCommentBox(!showCommentBox);
  };

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  const handleClick = () => {
    navigate(-1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const getComments = async () => {
    try {
      const response = await agent.legacyBookRequest.getAllComments(id);
      const data = response;
      console.log("comments", data);
    } catch (error) {
      // Handle error here if needed
    }
  };

  useEffect(() => getComments(), []);

  return (
    <Fragment>
      <SEO
        title="Natural Burials in Kendal, UK - Inspired Meadows"
        description="Explore natural burials in Kendal, UK at Inspired Meadows, offering eco-friendly burial options in serene, natural surroundings for a peaceful return to nature."
      />
      <section id="legacy-details-main">
        <div class="container">
          <button
            style={{ cursor: "pointer" }}
            onClick={handleClick}
            class="go-back"
          >
            <img src={arrow_left} alt="" /> Go back
          </button>
          <h2>Legacy Book</h2>
          <div class="legacy-profile-details">
            <div class="data">
              <div class="pic-data">
                <div class="profile-name">
                  <div class="pic">
                    <img src={mainImage} alt="Selected" />
                  </div>
                  <div class="name">
                    <h3>{BookData?.user?.name}</h3>
                    <span>
                      {formatDate(BookData?.birthDate)} -{" "}
                      {formatDate(BookData?.deathDate)}
                    </span>
                  </div>
                </div>
                <ul>
                  {BookData?.gallery.map((image, index) => (
                    <li key={index}>
                      <img
                        onClick={() => setMainImage(image)}
                        src={image}
                        alt={`Gallery ${index + 1}`}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <p>{BookData?.memoryLane}</p>
            </div>
          </div>
          <div class="comments-main">
            <div class="head">
              <h3>Comments</h3>
              <button onClick={showComment}>Add Comment</button>
            </div>

            <div class="comments">
              {showCommentBox && (
                <div class="comment-box comment-type">
                  <div class="comment-profile">
                    <img src="images/profile_example.png" alt="" />
                  </div>
                  <div class="comment-user">
                    <textarea placeholder="Add your comment..."></textarea>
                    <button class="submit-btn">Submit</button>
                  </div>
                </div>
              )}
              <div class="comment-box">
                <div class="comment-profile">
                  <img src="images/profile_example.png" alt="" />
                </div>
                <div class="comment-user">
                  <span>Name</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec ligula nibh, interdum non enim sit amet, iaculis
                    aliquet nunc.
                  </p>
                </div>
              </div>
              <div class="comment-box">
                <div class="comment-profile">
                  <img src="images/profile_example.png" alt="" />
                </div>
                <div class="comment-user">
                  <span>Name</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec ligula nibh, interdum non enim sit amet, iaculis
                    aliquet nunc.
                  </p>
                </div>
              </div>
              <div class="comment-box">
                <div class="comment-profile">
                  <img src="images/profile_example.png" alt="" />
                </div>
                <div class="comment-user">
                  <span>Name</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec ligula nibh, interdum non enim sit amet, iaculis
                    aliquet nunc.
                  </p>
                </div>
              </div>
              <div class="comment-box">
                <div class="comment-profile">
                  <img src="images/profile_example.png" alt="" />
                </div>
                <div class="comment-user">
                  <span>Name</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec ligula nibh, interdum non enim sit amet, iaculis
                    aliquet nunc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
