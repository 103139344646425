import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainView from "./MainView";
import SEO from "../SEO/SEO";
import useDocumentTitle from "../hooks/UseDocumentTitle";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const LegacyBook = (props) => {
  let mainProps = {};
  useDocumentTitle("Legacy Books");
  return (
    <div className="location-page">
      <SEO
        title="Legacy Book"
        description="Find the perfect resting place at Inspired Meadows. Explore our natural burial grounds , offering serene gardens of remembrance final resting places."
        keywords="Natural burial grounds, Woodland burials, Tranquil final resting places, Serene gardens of remembrance"
        href="https://www.inspiredmeadows.co.uk/legacy-book"
      />

      <MainView {...mainProps} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LegacyBook);
