import React from "react";
import bannerimg from "../../assets/images/Natural_bg_img.png";
import Natural_first from "../../assets/images/Natural_first.png";
import Natural_second from "../../assets/images/Natural_second.png";
import natural_three from "../../assets/images/natural_three.png";
import natural_four from "../../assets/images/natural_four.png";
import keyfirst from "../../assets/images/key-first.png";
import keySecond from "../../assets/images/key-second.png";
import keyThird from "../../assets/images/key-three.png";
import keyFourth from "../../assets/images/key-four.png";
import bgimg from "../../assets/images/bg_img.png";
import Faq from "./Faq";

const LazyImage = React.lazy(() => import("../Common/Optemize/LazyImage"));

const Greenburials = () => {
  return (
    <>
      <section id="banner-img-main">
        <div className="banner-overlay">

        </div>
        <div className="custom-banner-img">
          <div>
            <img src={bannerimg} alt="overview image" />
          </div>
          {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Green Burial</h1>
            <p>
              A final resting place that gives back to the earth, nurtures life,
              and celebrates your story.
            </p>
          </div>
        </div>
      </section>
      <section id="Burials-main" className="burials-new-main">
        <div className="container">
          <div className="row">
            <div className="Burials-head-col">
              <div className="Burials-head">
                <h2> Benefits of Natural Burials</h2>
                <p className="p-spacing">
                  Natural burials are far more than just an eco-friendly choice
                  - they’re more affordable, they’re easier to personalise, and
                  they make a profound statement about how we live, love, and
                  care for our planet. Here’s how they make a difference for
                  families and the environment.
                </p>
              </div>
            </div>
            <div className="burials-desk">
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <h3>Respecting the Planet</h3>
                  <p className="p-spacing">
                    Traditional burial practices often involve chemicals,
                    non-biodegradable materials, and significant land use. Green
                    burials, by contrast, minimise environmental impact. When
                    you choose a green burial, you're contributing to the
                    preservation of land and the nurturing of local ecosystems.
                  </p>
                  <p className="pt-6">
                    At Inspired Meadows, we allow families to plant trees and
                    flowers, creating a personalised plot that supports
                    biodiversity. Imagine your final resting place bringing life
                    to a forest or a blooming meadow - what better legacy to
                    leave behind to your great-great-great-great grandchildren?
                  </p>
                </div>
              </div>
              <div className="burials-half-col">
                <div className="burials-half">
                  <img src={natural_four} alt="" />
                  <h3>A Unique and Personal Touch</h3>
                  <p>
                    A green burial isn’t just a choice for the environment; it’s
                    also an opportunity to be remembered in a meaningful and
                    special way. But what do we mean by this? It’s simple: green
                    burials aren’t simply left alone once the ceremony is over.
                    As your body returns to the earth, your plot becomes a
                    fertile ground for planting flowers, trees, and plants.
                    Rather than a dreary gravestone in a crowded cemetery, your
                    resting spot becomes its own thriving ecosystem.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={Natural_first} alt="" />
                  <h3>Bring the Family Together</h3>
                  <p>
                    With green burials, we also make it possible for families to
                    plan thoughtfully. You can buy plots in advance, with
                    generous plot sizes that are 10 times larger than industry
                    standards, with plenty of room for multiple loved ones, as
                    well as your pets.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={Natural_second} alt="" />
                  <h3>Forever Peace of Mind</h3>
                  <p>
                    Unlike many traditional burial plots that require renewal,
                    we at Inspired Meadows offer a “forever plot” with a
                    999-year right of burial. Families don’t have to worry about
                    the added stress of renewal costs or the government re-using
                    your grave - your final resting place remains untouched and
                    well-maintained for generations to come.
                  </p>
                </div>
              </div>
              <div className="burials-col">
                <div className="burials">
                  <img src={natural_three} alt="" />
                  <h3>Easily Accessible for Loved Ones</h3>
                  <p>
                    Finally, we know that walking through a crowded churchyard
                    cemetery isn’t always the biggest mood lifter. That’s why
                    each burial plot on our meadowland is assigned a unique
                    address so family members can visit with ease. Your loved
                    ones won’t be left wandering through endless rows of graves
                    - they will know exactly where to go to pay their respects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="natural-key-main" className="natural-key-newmain">
        <div className="container">
          <div className="row">
            <div className="key-head-col">
              <div className="key-head">
                <h2>How Does an Eco Burial Take Place?</h2>
                <p>
                  Natural burials offer a unique and meaningful approach to
                  honouring the life of a loved one while embracing the beauty
                  and tranquility of nature. With a focus on sustainability and
                  environmental preservation, natural burials provide numerous
                  benefits for both individuals and the planet.
                </p>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyfirst} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 1: Choose Your Plot</h3>
                  <p>
                    The process begins with selecting a burial site at Inspired
                    Meadows. With vast, serene landscapes to choose from, you’ll
                    find the perfect spot to reflect your personal style and
                    connection to nature.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keySecond} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 2: Personalise Your Space</h3>
                  <p>
                    Add your personal touch by choosing natural elements like
                    specific trees, flowers, or plants to nurture over time.
                    Whether you want to create a vibrant garden or a tranquil
                    forested grove, your plot can reflect the life you lived.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyThird} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 3: Final Resting Arrangements</h3>
                  <p>
                    We at Inspired Meadows specialise in cremation burials,
                    offering a sustainable alternative to traditional casketed
                    burials. Using a biodegradable urn, cremated remains become
                    part of the earth without leaving a lasting environmental
                    footprint.
                  </p>
                </div>
              </div>
            </div>
            <div className="key-chain-col">
              <div className="key-chain">
                <div className="key-chain-img">
                  <span>
                    <img src={keyFourth} alt="" />
                  </span>
                </div>
                <div className="key-chain-head">
                  <h3>Step 4: Ongoing Maintenance</h3>
                  <p>
                    Unlike DIY natural burial plots, we at Inspired Meadows keep
                    each space lovingly preserved through sustainable
                    maintenance practices, all for a one-time fee of £175. Your
                    plot will remain a source of pride and beauty for a thousand
                    years (literally!), without putting the financial burden on
                    your family.
                  </p>
                </div>
              </div>
            </div>
            <div className="chain-footer-col"></div>
          </div>
        </div>
      </section>
      <section id="why-green-burials">
        <div className="container">
          <div className="row">
            <div className="why-green-head-col">
              <div className="why-green-head">
                <h2>Why Choose Inspired Meadows Green Burials?</h2>
                <p>
                  At Inspired Meadows, we believe the way we say goodbye should
                  reflect how we’ve chosen to live—with care, mindfulness, and
                  purpose. Here’s what makes us the preferred choice for green
                  burials in the UK.
                </p>
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h4>Commitment to Sustainability</h4>
                <p>
                  Everything we do at Inspired Meadows prioritises the health
                  and longevity of our planet. Whether it's eliminating harmful
                  chemicals, using biodegradable materials, or nurturing native
                  plant species, green burial is at the heart of our mission.
                </p>
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h4>A Practical Yet Meaningful Choice</h4>
                <p>
                  Talking about afterlife planning doesn’t need to be taboo.
                  We’re passionate about opening up this conversation for
                  everyone—including younger generations—because thoughtful
                  preparation ensures peace of mind.
                </p>
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h4>Flexible Payment Plans</h4>
                <p>
                  At Inspired Meadows, we understand that financial worries can
                  make burial planning difficult for families. That’s why we
                  offer flexible financing options - with a 25% downpayment and
                  monthly financing options on the table.
                </p>
              </div>
            </div>
            <div className="why-green-cols">
              <div className="why-green">
                <h4>Largest Plot Sizes in the Industry</h4>
                <p>
                  Space matters, especially if you’re looking to be buried
                  alongside loved ones. At Inspired Meadows, our burial plots
                  (16m²) and ashes plots (8m²) are larger than the industry
                  standard, giving you and your family room to create something
                  truly special.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faq />
      <section id="new-cta-main">
        <div className="container">
          <div className="new-cta">
            <img src={bgimg} />
            <div className="new-cta-details">
              <h3>Take the First Step Towards a Greener Legacy</h3>
              <p>
                Inspired Meadows is more than a burial ground—it’s a living,
                breathing tribute to sustainability, love, and memory. Show your
                commitment to the planet and create a forever space uniquely
                yours. 
              </p>
              <p>
                Contact our team today to find a plot that fits your vision and
                values.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Greenburials;
