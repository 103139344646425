import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import agent from "../../agent";

import { FETCH_LEGACY_BOOKS } from "../../constants/actionTypes";
import Loader from "../components/Loader";
import searchImage from "../../assets/images/search_icon.svg";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => ({
  ...state,
  legacyData: state.common.legacyData,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadPage: async (searchQuery) => {
    try {
      const response = await agent.legacyBookRequest.getAllLegacyBooks(
        searchQuery || ""
      );
      const data = response;
      dispatch({
        type: FETCH_LEGACY_BOOKS,
        payload: data,
      });
    } catch (error) {
      // Handle error here if needed
    }
  },
});

const MainView = (props) => {
  const { legacyData, onLoadPage } = props;
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      //   setIsLoading(true);
      try {
        await onLoadPage(searchQuery);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  const maxLength = 250;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (legacyData) {
      setBooks(legacyData);
    }
  }, [legacyData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const generateInitials = (name) => {
    const nameParts = name.split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else if (nameParts.length > 1) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      );
    }
    return "";
  };

  return (
    <Fragment>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <section id="legacy-main">
            <div class="container">
              <div class="legacy-container">
                <div class="search-legacy">
                  <h2>Legacy Book</h2>
                  <div class="search-field">
                    <input
                      type="search"
                      placeholder="Search by name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <span>
                      <img src={searchImage} alt="" />
                    </span>
                  </div>
                </div>
                <div class="view-legacy">
                  <h3>View Legacies</h3>
                  <div class="legacy-list">
                    {books?.map((book, index) => (
                      <div class="box" key={index}>
                        <div class="profile-img">
                          {book?.user?.image ? (
                            <img src={book?.user?.image} alt="" />
                          ) : (
                            generateInitials(book?.user?.name)
                          )}
                        </div>
                        <div class="detail">
                          <Link
                            to={`/legacy-book/${book?.id}`}
                            key={index}
                            class="box"
                          >
                            <h4>{book?.user?.name}</h4>
                          </Link>
                          <span>
                            {formatDate(book?.birthDate)} -{" "}
                            {formatDate(book?.deathDate)}
                          </span>
                          <p>
                            {" "}
                            {book?.memoryLane &&
                            book?.memoryLane.length > maxLength
                              ? `${book?.memoryLane.substring(0, maxLength)}...`
                              : book?.memoryLane}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
