import React, { useState } from "react";
import AccordionItem from "../FAQ/AccordionItem";

const Faq = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const toggleAccordion = (index) => {
    if (expandedIndex === index) {
      // Clicked on an already open item, so close it.
      setExpandedIndex(null);
    } else {
      // Clicked on a new item, so open it.
      setExpandedIndex(index);
    }
  };
  const data = [
    {
      question: "What happens at a natural burial?",
      answer:
        "At a natural burial, the body is laid to rest in a biodegradable coffin or shroud, without the use of embalming chemicals. The burial takes place in a designated natural burial ground, often in a woodland or meadow setting like ours in the beautiful Lake District. The grave is typically more shallow than in a traditional burial, to promote natural decomposition.",
    },
    {
      question: "How much does a natural burial cost in the UK?",
      answer:
        "The cost of a natural burial in the UK can vary depending on the location and services provided. On average, prices range from £1,500 to £4,000, which often includes the plot, interment fees, and a biodegradable coffin or shroud. This is generally less expensive than a traditional funeral, and at Inspired Meadows, we offer payment plans to allow you to cover the cost in instalments.",
    },
    {
      question: "How long does a natural burial last?",
      answer:
        "A natural burial is intended to be a permanent resting place. However, unlike traditional cemeteries where graves are maintained indefinitely, natural burial grounds allow nature to take its course over time. The grave may become overgrown with native vegetation, creating a natural living memorial.",
    },
    {
      question: "Can I be buried without a coffin in the UK?",
      answer:
        "Yes, in the UK, it is legal to be buried without a coffin. Many natural burial grounds allow for the use of a simple shroud made from biodegradable materials such as cotton, linen, or wool. This option further reduces the environmental impact of the burial.",
    },
    {
      question:
        "How long does it take for a body to decompose in a natural burial?",
      answer:
        "This generally depends on things such as soil conditions, moisture levels, and the presence of microorganisms. In general, it can take between 6 months to 2 years for a body to decompose naturally, but the shallow depth of the grave in a natural burial facilitates this process.",
    },
    {
      question: "How do you prepare a body for a natural burial?",
      answer:
        "For a natural burial, the body is typically washed and dressed in simple, biodegradable clothing. Embalming is avoided to allow for natural decomposition, and in some cases, dry ice may be used for temporary preservation until the burial can take place.",
    },
  ];
  return (
    <div>
      <section id="Faq-main" className="faq-new-main">
        <div className="container">
          <div className="row">
            <div className="faq-col">
              <div className="faq">
                <h2>Frequently Asked Questions</h2>
                <ul>
                  {data?.map((ques, index) => (
                    <AccordionItem
                      key={index}
                      number={index}
                      title={ques?.question}
                      content={ques?.answer}
                      isExpanded={expandedIndex === index}
                      toggleAccordion={toggleAccordion}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
