import React from "react";
import bgimg from "../../assets/images/bg_img.png";
import familytypes from "../../assets/images/family_types_img.png";
import bannerimg from "../../assets/images/Natural_bg_img.png";
import familyburialImg from "../../assets/images/family_burial.png";
import memorialImg from "../../assets/images/memorial_img.png";
import tributeImg from "../../assets/images/tribute_img.png";
import memorialPlots from "../../assets/images/memorial_garden.jpg";
import NatureOne from "../../assets/images/Nature_first.jpg";
import NatureThree from "../../assets/images/Nature_third.jpg";
import NatureFive from "../../assets/images/Nature_five.jpg";

const Memorialgardien = () => {
  return (
    <>
      <section id="banner-img-main">
      <div className="banner-overlay">

</div>
        <div className="custom-banner-img">
          <div>
            <img src={bannerimg} alt="overview image" />
          </div>
          {/* <img src={bannerimg} alt="banner-img" loading="lazy" /> */}
        </div>
        <div className="custom-banner-text">
          <div className="container">
            <h1>Memorial Gardens</h1>
            <p>
              Create a serene legacy in our beautifully crafted memorial
              gardens, where your loved ones can find peace together, forever.
            </p>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="about-memorial">
        <div className="container">
          <div className="row">
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={memorialPlots} />
              </div>
            </div>
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>About Our Memorial Gardens</h2>
                <p>
                  Memorial gardens offer a serene and sustainable alternative to
                  traditional cemeteries. At Inspired Meadows, we believe that
                  memorial spaces should honour the departed while preserving
                  our natural landscapes for future generations. Situated within
                  sprawling, untouched meadowland in the Lake District, our
                  burial grounds offer families a place of peace, reflection,
                  and permanence.  
                </p>
                <p>
                  Unlike overcrowded traditional cemeteries, our memorial
                  gardens provide not only lasting dignity but also address the
                  pressing issue of burial space scarcity in the UK. With plots
                  designed to become part of the flourishing natural
                  environment, we at Inspired Meadows offer a thoughtful and
                  lasting tribute to loved ones without succumbing to the
                  challenges faced by conventional burial practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about-family-main" className="memorial-option-main">
        <div className="container">
          <div className="row">
            <div className="family-detail-col">
              <div className="family-detail">
                <h2>Burial and Memorial Options</h2>
                <p>
                  We’re proud to offer a variety of environmentally considerate
                  burial and memorial options designed to meet your family’s
                  needs. Our natural burials ensure that every plot becomes an
                  integral part of the ecosystem, promoting growth and life in
                  an otherwise solemn space.  
                </p>
                <p>
                  Unlike traditional cemeteries where graves may be disturbed
                  after 75 years, we secure all plots for an extraordinary 999
                  years, giving families the assurance of a true "forever"
                  resting place. Every burial site is generously spacious—ten
                  times larger than the standard plot in a typical
                  cemetery—giving your loved one the room that represents the
                  legacy they leave behind. 
                </p>
                <p>
                  Memorial gardens at Inspired Meadows are carefully nurtured to
                  create a living tribute, replacing traditional headstones with
                  vibrant, thriving landscapes. These natural spaces provide a
                  comforting and peaceful environment where families can gather,
                  reflect, and celebrate cherished memories for years to come
                </p>
              </div>
            </div>
            <div className="family-burial-img-col">
              <div className="family-burial-img">
                <img src={memorialImg} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="memorial-tribute-service">
        <div className="container">
          <div className="row">
            <div className="tribute-head-col">
              <div className="tribute-head">
                <h2>Personalisation and Tribute Services</h2>
                <img src={tributeImg} />
                <p>
                  We understand that every individual is unique, and their
                  legacy deserves the utmost care and thoughtfulness. Inspired
                  Meadows offers an array of personalisation options to ensure
                  every burial is as meaningful and distinctive as the life it
                  commemorates. 
                </p>
                <p>
                  Our tribute services include planting trees, shrubs, or
                  flowers selected by the family to create a beautiful, living
                  memorial to their loved one.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="types-family-main">
        <div className="container">
          <div className="row">
            <div className="type-family-head-col">
              <div className="type-family-head">
                <h2>Why Choose Our Memorial Gardens?</h2>
                <p>
                  The overcrowding crisis in UK cemeteries has raised important
                  questions about how we honour and memorialise our loved ones,
                  with some suggesting grave re-use. At Inspired Meadows, we
                  offer a forward-thinking alternative to these outdated
                  practices.
                </p>
              </div>
            </div>
            <div className="family-types-col">
              <div className="family-types">
                <div className="family-types-box">
                  <div className="types-details">
                    <h3>Permanent Peace of Mind</h3>
                    <p>
                      Our 999-year plot guarantees the comfort of knowing that
                      your loved ones’ resting places will remain undisturbed,
                      preserving their legacy for generations to come.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureOne} />
                  </div>
                </div>
                <div className="family-types-box">
                  <div className="family-types-img">
                    <img src={NatureThree} />
                  </div>
                  <div className="types-details">
                    <h3>Eco-Friendly Solutions</h3>
                    <p>
                      Natural burials are not just sustainable—they are
                      restorative. Each plot contributes to revitalising the
                      land, replacing concrete and headstones with trees and
                      flowers that enhance the natural beauty of our
                      countryside.
                    </p>
                  </div>
                </div>
                <div className="family-types-box third">
                  <div className="types-details">
                    <h3>A Dignified Future</h3>
                    <p>
                      By choosing a memorial garden, you’re supporting a
                      solution that respects both our loved ones and the
                      environmental challenges we face. Unlike reusing graves or
                      overcrowding burial grounds, Inspired Meadows focuses on
                      creating spaces that align with the values of dignity,
                      sustainability, and peace.
                    </p>
                  </div>
                  <div className="family-types-img">
                    <img src={NatureFive} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="new-cta-main" className="memorial-cta-new">
        <div className="container">
          <div className="new-cta">
            <img src={bgimg} />
            <div className="new-cta-details">
              <h3>Speak to Us Today and Learn More</h3>
              <p>
                Interested in planning a forever plot for you and your family?
                Our caring team is here to guide you through every step of the
                way.
              </p>
              <p>
                Whether you’d like to visit in person or speak with us about
                personalising your loved one's legacy, we invite you to get in
                touch today. Together, we can honour their memory while securing
                a sustainable future for generations to come.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Memorialgardien;
