import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const MainView = (props) => {
  return (
    <Fragment>
      <section className="plans-sec">
        <div className="noaccess-outer text-center mt-10">
          <h2>404</h2>
          <h4>Page not found!</h4>
          <hr />
          <NavLink className="btn a-links" to="/">
            Back to Home
          </NavLink>
        </div>
      </section>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
