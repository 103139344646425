import { Fragment, useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

import BannerImg from '../../assets/images/product_page.png';

import BurialPlots from './BurialPlots';
import MemorialDetailModal from './MemorialDetailModal';
import MemorialFlora from './MemorialFlora';
import MemorialStones from './MemorialStones';
import PlotDetailModal from './PlotDetailModal';
import SuccessModal from './SuccessModal';
import UrnPlots from './UrnPlots';
import UrnsAndCaskets from './UrnsAndCaskets';
import { purchaseData } from '../Common/common';
import msgbox from "../../assets/images/msg-box.png";
import anglerighticon from "../../assets/images/angle-right-icon.png";
import whatsupicon from "../../assets/images/Phone-icon.png";
import email_icon from "../../assets/images/email_icon.png";
import SEO from '../SEO/SEO';



const categories = [
  { id: 1, text: 'Urn Plots', val: 'urnPlots' },
  // { id: 0, text: 'Burial Plots', val: 'burialPlots' },
  { id: 2, text: 'Flowers ', val: 'memorialFlora' },
  { id: 3, text: 'Trees ', val: 'trees' },
  // { id: 3, text: 'Memorial Plaques', val: 'memorialStones' },
  // { id: 4 , text: 'Urns and Caskets', val: 'urnsAndCaskets' },
];
const categories1 = [
  // { id: 0, text: 'Burial Plots', val: 'burialPlots' },
  { id: "urn-plots", text: 'Urn Plots', val: 'urnPlots' },
  { id: "flowers", text: 'Flowers', val: 'memorialFlora' },
  { id: "trees", text: 'Trees ', val: 'trees' },
  // { id: 3, text: 'Memorial Plaques', val: 'memorialStones' },
  // { id: 4 , text: 'Urns and Caskets', val: 'urnsAndCaskets' },
  // { id: 5 , text: 'Urns and Caskets', val: 'urnsAndCaskets' },
];

const MainView = (props) => {
  const { viewMode } = props
  const [activeCategory, setActiveCategory] = useState('urnPlots');
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [heading , setHeading] = useState("")

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const category = categories1.find((category) => category.id === parseInt(id));
      if (category) {
        setActiveCategory(category.val);
      }
    }
  }, [id]);
  
  useEffect(() => {
    if (activeCategory === 'memorialFlora'){
      setTitle("Flowers - Inspired Meadows")
      setDescription("Buy funeral flowers in the UK from Inspired Meadows, offering beautiful, eco-friendly arrangements to honor your loved ones with a touch of natural elegance.")
      setHeading("Flowers")
    }else if (activeCategory === 'trees'){
      setTitle("Trees - Inspired Meadows")
      setDescription("Shop our selection of trees for sale, perfect for adding beauty, shade, and sustainability to your garden or landscape. Choose eco-friendly trees for every space.")
      setHeading("Trees")
    }else{
      setTitle("Buy Burial Urn Plots in the UK - Inspired Meadows")
      setDescription("Purchase burial urn plots in the UK at Inspired Meadows, offering peaceful, eco-friendly resting places with beautiful natural surroundings for your loved ones.")
      setHeading("Buy Burial Urn Plots")
    }
    
  }, [activeCategory])

  return (
    <Fragment>

<SEO
        title={title}
        description={description}
        keywords="Biodegradable caskets, Eco-friendly burials, Sustainable memorialization"
         href="https://www.inspiredmeadows.co.uk/products"
      />


      {viewMode === undefined &&
        <section id='banner-img-main'>
          <div className='custom-banner-img'>
            <img src={BannerImg} alt='banner-img' />
          </div>
          <div className='custom-banner-text'>
            <div className='container'>
              <h1>{heading}</h1>
            </div>
          </div>
        </section>
      }

      <section id='product-main'>
        <div className='container-two'>
          <div className='products'>
            <div className='category'>
              <h2>Shop by category:</h2>
              <ul>
                {categories.map((category, idx) => (
                  <li key={idx}>
                    <span
                      className={`category-link ${activeCategory === category.val ? 'category-active' : ''
                        }`}
                      onClick={() => setActiveCategory(category.val)}
                    >
                      <span className='square'></span>
                      {category.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <Fragment>
            {activeCategory === 'urnPlots' && <UrnPlots activeCategory={activeCategory} id={props.id} />}
            {/* {activeCategory === 'burialPlots' && <BurialPlots activeCategory={activeCategory} id={props.id} />} */}
            {activeCategory === 'memorialFlora' && <MemorialFlora activeCategory={activeCategory} />}
            {activeCategory === 'trees' && <MemorialFlora activeCategory={activeCategory} />}
            {/* {activeCategory === 'memorialStones' && <MemorialStones activeCategory={activeCategory} />}
            {activeCategory === 'urnsAndCaskets' && <UrnsAndCaskets activeCategory={activeCategory} />} */}
          </Fragment>
          </div>
        </div>
      </section>
      

      <section id="Adviser-main">
        {viewMode === undefined ?
          (
            <div className="future-planing overview-future-planing">
              <div className="container-two">
                <div className="row">
                  <div className="talk-adviser-col">
                    <div className="talk-adviser">
                      <div className="msg-img">
                        <img src={msgbox} alt="message box" />
                      </div>
                      <div className="adviser-head">
                        <h3>Speak to an Advisor</h3>
                        <p>For more information please contact us for a no obligation call. Please click on SCHEDULE A CALL button</p>
                      </div>
                      <div className="adviser-contact">
                        <Link to="/schedule-a-call">Schedule a Call <img src={anglerighticon} alt="" /></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          : (
            <div className="get-info-col">
              <div className="get-info">
                <div className="get-head">
                  <div className='get-heading'>
                    <span>Get in touch</span>
                    <p>Please contact us for more information</p>
                  </div>
                  <div className='get-link'>Schedule a Call <img src={anglerighticon} alt="Email icon" /></div>
                </div>
                <div className="get-contact">
                  <ul>
                    <li><div className='get-contact-link'> <img src={email_icon} alt="Email icon" /> info@inspiredmeadows.co.uk</div></li>
                    <li><div className='get-contact-link' > <img src={whatsupicon} alt="Phone image" />(0) 1392 576 302</div></li>
                  </ul>
                </div>
              </div>
            </div>
          )}
      </section>

      <PlotDetailModal />
      <MemorialDetailModal />
      <SuccessModal />
    </Fragment>
  );
};

export default MainView;
