import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { FrontendLayout } from "../Layouts";
import { baseRoutes } from "./routes";

import Error404 from "../Error404";
import Home from "../Home";
import PdfView from "../Includes/pdfView";
import CookiePolicy from "../TermsAndCondition/CookiePolicy";
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

const RouteList = (props) => {
  return (
    <Fragment>
      <Routes>
        {baseRoutes.map((route, i) => {
          let component = route.component;
          return (
            <Route
              key={i}
              {...route}
              element={<FrontendLayout>{component}</FrontendLayout>}
            />
          );
        })}
        {/* Set the Home route as the index route */}
        <Route
          index
          element={
            <FrontendLayout>
              <Home />
            </FrontendLayout>
          }
        />
        {/* 404 Route */}
        {/* <Route
                    path="*"
                    element={(
                        <FrontendLayout>
                            <Error404 />
                        </FrontendLayout>
                    )}
                /> */}
        <Route path="/pdf" element={<PdfView />} />
      </Routes>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteList);
