import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  validate as FormValidate,
  ListErrorMessages,
} from "../../constants/Validations";
import {
  RESET_PASSWORD,
  RESET_PASSWORD_EMPTY,
} from "../../constants/actionTypes";
import agent from "../../agent";
import ProfileDetail from "./includes/ProfileDetail.js";
import ChangePassword from "./includes/ChangePassword.js";
import MyOrders from "./includes/MyOrders";
import SEO from "../SEO/SEO.jsx";
import LegacyBook from "./includes/LegacyBook.js";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const ProfilePage = (props) => {
  const [showDetail, setShowDetail] = useState(true);
  const [showChangePass, setShowChangePass] = useState(false);
  const [showOrderPass, setShowOrderPass] = useState(false);
  const [showLegacyPass, setShowLegacyPass] = useState(false);

  const handleShowDetailPage = () => {
    setShowChangePass(false);
    setShowDetail(true);
    setShowOrderPass(false);
    setShowLegacyPass(false);
  };

  const handleShowUpPassPage = () => {
    setShowDetail(false);
    setShowChangePass(true);
    setShowOrderPass(false);
    setShowLegacyPass(false);
  };

  const handleShowUpOrderPage = () => {
    setShowChangePass(false);
    setShowDetail(false);
    setShowOrderPass(true);
    setShowLegacyPass(false);
  };
  const handleShowUpLegacyPage = () => {
    setShowChangePass(false);
    setShowDetail(false);
    setShowOrderPass(false);
    setShowLegacyPass(true);
  };

  let detailProps = {
    showDetail,
    handleShowUpPassPage,
    handleShowUpOrderPage,
    handleShowUpLegacyPage,
  };

  let updtPassProps = {
    showChangePass,
    handleShowDetailPage,
    handleShowUpOrderPage,
    handleShowUpLegacyPage,
  };

  let orderProps = {
    showOrderPass,
    handleShowDetailPage,
    handleShowUpPassPage,
    handleShowUpLegacyPage,
  };

  let legacyProps = {
    showLegacyPass,
    showOrderPass,
  };

  return (
    <section id="profile-main">
      <SEO title="Inspired Meadows | Member's Profile" />
      <div className="container">
        <div className="row">
          <div className="account-head-col">
            <div className="account-head">
              <h1>Account Settings</h1>
            </div>
          </div>
          <div className="account-main-col">
            <div className="account-main">
              <ul>
                <li>
                  <Link
                    className={showDetail ? "profile-active" : ""}
                    to="#"
                    onClick={handleShowDetailPage}
                  >
                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    Personal Information
                  </Link>
                </li>
                <li>
                  <Link
                    className={showChangePass ? "profile-active" : ""}
                    to="#"
                    onClick={handleShowUpPassPage}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link
                    className={showOrderPass ? "profile-active" : ""}
                    to="#"
                    onClick={handleShowUpOrderPage}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    My Orders
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={showLegacyPass ? "profile-active" : ""}
                    to="#"
                    onClick={handleShowUpLegacyPage}
                  >
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    Legacy Book
                  </Link>
                </li> */}
              </ul>

              <ProfileDetail {...detailProps} />
              <ChangePassword {...updtPassProps} />
              <MyOrders {...orderProps} />
              <LegacyBook {...legacyProps} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
